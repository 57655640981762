import { Comment, type User } from "@prisma/client";
import { type IProvider } from "@web3auth/base";
import { atom, atomFamily } from "recoil";
import type Decimal from "decimal.js";
import { type CommentFeed } from "~/components/Modules/Common/CommentListModule";

export const userAtom = atom<User>({
  key: "userAtom",
  default: undefined,
});

export const searchTextAtom = atom<string>({
  key: "searchTextAtom",
  default: "",
});

export const lastPathnameAtom = atom<string>({
  key: "lastPathnameAtom",
  default: "",
});

export const providerAtom = atom<IProvider | null>({
  key: "providerAtom",
  default: null,
});

export const showLoaderOverlayAtom = atom<boolean | number>({
  key: "showLoaderOverlayAtom",
  default: false,
});

export const isScrolledBottomAtom = atom<boolean>({
  key: "isScrolledBottomAtom",
  default: false,
});

export const likedFeedIdsAtom = atom<number[]>({
  key: "likedFeedIdsAtom",
  default: [],
});

export const likedCommentIdsAtom = atom<number[] | undefined>({
  key: "likedCommentIdsAtom",
  default: undefined,
});

export const refreshCountAtom = atom<number>({
  key: "refreshCountAtom",
  default: 0,
});

export const refreshBalanceCountAtom = atom<number>({
  key: "refreshBalanceCountAtom",
  default: 0,
});

export const refreshSessionCountAtom = atom<number>({
  key: "refreshSessionCountAtom",
  default: 0,
});

export const pointBalanceAtom = atom<Decimal | undefined | null>({
  key: "pointBalanceAtom",
  default: undefined,
});

export const lastDatasAtom = atom<any[]>({
  key: "lastDatasAtom",
  default: [],
});

export const configsAtom = atom<any[]>({
  key: "configsAtom",
  default: [],
});

export const lastDataTypeAtom = atom<string>({
  key: "lastDataTypeAtom",
  default: "",
});

export const feedCommentCountMapAtom = atom<Map<number, number>>({
  key: "feedCommentCountMapAtom",
  default: new Map(),
});

export const voteCommentCountMapAtom = atom<Map<number, number>>({
  key: "voteCommentCountMapAtom",
  default: new Map(),
});

export const parentCommentAtom = atom<CommentFeed | null>({
  key: "parentCommentAtom",
  default: null,
});

export const networkAtom = atom<"BINANCE" | "ETHEREUM">({
  key: "networkAtom",
  default: "BINANCE",
});

export const fandomDetailTabKeyAtomFamily = atomFamily<
  string,
  number | undefined
>({
  key: "fandomDetailTabKeyAtomFamily",
  default: "feed", // 기본값 설정
  effects_UNSTABLE: (fandomId) => [
    ({ setSelf, onSet }) => {
      // 로컬 스토리지 키를 fandomId에 따라 동적으로 생성
      const localStorageKey = `fandomDetailTabKeyAtom_${fandomId || "default"}`;

      // 페이지 로드 시 로컬 스토리지에서 값을 로드하거나 기본값을 사용
      const savedValue = localStorage.getItem(localStorageKey);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      } else {
        setSelf("feed"); // 기본값으로 설정
      }

      // 상태가 변경될 때마다 로컬 스토리지에 저장
      onSet((newValue) => {
        localStorage.setItem(localStorageKey, JSON.stringify(newValue));
      });
    },
  ],
});
